import { Component, Inject, OnInit, Optional } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxNumToWordsService } from "ngx-num-to-words";
import Swal from "sweetalert2";
import { environment } from "../../../environments/environment";
import { AppService } from "../../services/helpers/app.service";
import { EventsService } from "../../services/helpers/events.service";
import { FormatNumbersService } from "../../services/helpers/format-numbers.service";
import { HelperService } from "../../services/helpers/helper.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../invoices/invoice-details/invoice-details.component";
import { ReceiptDetailsComponent } from "../receipts/receipts-table/receipt-details/receipt-details.component";

@Component({
  selector: "ngx-receipt",
  templateUrl: "./receipt.component.html",
  styleUrls: ["./receipt.component.scss"],
})
export class ReceiptComponent implements OnInit {
  id: string;
  data: any;
  numberInWords = "";
  numberInWordsar = "";
  lang;
  lastIndex
  show: boolean;
  unitType: any;
  options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  date;
  receipt;
  logo;
  start_end_date
  company_info
  constructor(
    private appservice: AppService,
    private ngxNumToWordsService: NgxNumToWordsService,
    private avRouter: ActivatedRoute,
    private helper: HelperService,
    private eventsService: EventsService,
    private router: Router,
    public formate: FormatNumbersService,
    private tranlateService: TranslateService,
    @Optional() public dialogRef: MatDialogRef<ReceiptDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data_receipts: DialogData
  ) {
    document.body.style.overflow = "auto";
    this.lang = localStorage.getItem('rental_lang')
    if (this.avRouter.snapshot.params["id"]) {
      this.id = this.avRouter.snapshot.params["id"];
    }
    if (data_receipts) {
      this.data = data_receipts["data"];
      this.date = new Date(this.data.receipts?.date_of_payment);
      this.data.service_fees =
        Number(this.data?.commission) +
        Number(this.data.fees) +
        Number(this.data?.fixed_fees) +
        Number(this.data?.round_up);
      this.changeDate(this.date);
    }
  }

  ngOnInit(): void {
    this.getCompanyData(localStorage.getItem('company_id'))
    if (this.id !== undefined) {
      this.helper.showSpinner();
      this.appservice.GET("receipt/" + this.id).subscribe(
        (resp) => {
          this.data = resp;
          this.date = new Date(this.data.date_of_payment)
          this.getLang(resp?.invoices[0]?.unit?.building?.project[0]?.company_id || resp?.invoices[0]?.contract?.actors_firstparty_id);
          this.changeDate(this.date )
          let today: any = new Date();
          const dd = String(today.getDate()).padStart(2, "0");
          const mm = String(today.getMonth() + 1).padStart(2, "0");
          const yyyy = today.getFullYear();

          today = yyyy + "-" + mm + "-" + dd;
          if (today > resp?.date_to) {
            this.show = false;
          } else {
            this.show = true;
          }
          this.data.service_fees =
            Number(this.data?.commission) +
            Number(this.data?.fees) +
            Number(this.data?.fixed_fees) +
            Number(this.data?.round_up);
          this.data.receipt_id = this.data.id;
                this.helper.hideSpinner();
              },
              (err) => {
                this.helper.hideSpinner();
                this.helper.showToastDanger(
                  "Error",
                  err.error.errors[0]
          );
        }
        );
      }
      this.lastIndex = this.data.invoices.length-1;      
    }
    calcTotal(...values){
  let total = 0
  for (let index = 0; index < values.length; index++) {
    total += Number(values[index])
  }
  return total
}actors_firstparty_id
getCompanyData(id){
  this.appservice.GET(`company_info/${id}`).subscribe((res)=>{
    this.logo = res?.data?.logo
    this.company_info = res?.data
  })
}
copyLink(){
  Swal.fire({
    title: this.tranlateService.instant("Do You want to share this link ?"),
    showCancelButton: true,
    confirmButtonText: this.tranlateService.instant('Yes'),
    cancelButtonText: this.tranlateService.instant('No')
  }).then((result) => {
    if (result.isConfirmed) {
      let url = window.location.origin +'/receipt/'+this.data.receipt_id
     navigator.clipboard.writeText(url)
      Swal.fire(this.tranlateService.instant('Link has been copied!'), '', 'success')
    }
  })
}
  changeDate(date) {
    if (this.lang == "en") {
      this.date = this.date.toLocaleDateString("en-EG", this.options);
    } else {
      this.date = this.date.toLocaleDateString("ar-EG", this.options);
    }
    return this.date;
  }
  print() {
    this.router.navigate(["print", this.data.receipt_id]).then(()=>{
      if(!this.id){
        window.location.reload()
      }
    })
  }

  exportFile() {
    const slug = "invoices/saveInvoice/" + this.id;
    this.appservice.openUrl(slug);
  }

  pdfFile() {
    const slug = "invoices/saveInvoicePDF/" + this.id;
    this.appservice.openUrl(slug);
  }

  toggleMenu() {
    document.getElementById("dropdownmenu")?.classList.toggle("show-drop-down");
  }
  sendUrl() {
    this.helper.showSpinner();
    const id = this.id;
    this.appservice.GET(`invoices/sendURL/${id}`).subscribe(
      (res) => {
        this.helper.hideSpinner();
        this.helper.showToastSuccess("Success", "Invoice is sent to Payer");
      },
      (err) => {
        this.helper.hideSpinner();
        this.helper.showToastDanger(
          "Error",
          err.error.errors[0]
        );
      }
    );
  }
  floatNum(item: any) {
    return parseFloat(item).toFixed(2);
  }
  postinvoice() {
    const invoiceObj = {};
    if (this.data.status_id !== "2") {
      invoiceObj[this.id] = (this.data?.contract?.amount || this.data?.amount_total) - this.data?.discount;
      const sentObj = {
        payment_method: 1,
        invoices: invoiceObj,
        user_id: localStorage.getItem("userId"),
      };
      this.appservice.POST(`receipt`, sentObj).subscribe(
        (data) => {
          this.receipt.commission = data?.commission; 
          this.receipt.fees = data?.fees; 
          this.receipt.amount_paid = data?.amount_text;
          this.helper.showToastSuccess(
            "Thanks for using Boyot",
            "Payment successfully"
          );
          window.location.href = environment.URL;
        },
        (err) => {
          this.helper.hideSpinner();
          this.helper.showToastDanger(
            "Error",
            err.error.errors[0]
          );
        }
      );
    }
  }
  make_date(date) {
    var dateObj = new Date(date);
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var final = year + "/" + month + "/" + day;
    return final;
  }
  calcAmount(amount, commission, fees) {
    let newamount = amount - (commission - fees);
    return newamount;
  }
  getLang(company_id) {
    this.appservice
      .POST("hisLanguage", { user_id: company_id })
      .subscribe((res) => {
        this.lang = res.data.language == "1" ? "en" : "ar";
        this.tranlateService.use(this.lang)
      });
  }
  getjson(data) {
    try {
      const object = JSON.parse(data);
      return object?.result;
    } catch (e) {
      return ' ';
    }
  }
}
